import React, { useState } from 'react';

import { MDBWaves } from 'mdbreact';
import classNames from 'classnames';
import { navigate } from 'gatsby';

const customLink = (props) => {

    const [cursorPos, setCursorPos] = useState({})

    const handleClick = (e) => {
        if (!props.disabled) {
            e.stopPropagation();
            let cursorPos = {
                top: e.clientY,
                left: e.clientX,
                time: Date.now()
            };
            setCursorPos({ cursorPos });
        }
    };

    const {
        children,
        className,
        disabled,
        location,
        to,
        target,
    } = props;

    const classes = classNames(
        "nav-item",
        disabled ? "disabled" : "Ripple-parent",
        location === to && "active",
        className
    );

    return (
        <>
            {(!target)?
            <li
                className={classes}
                onMouseUp={handleClick}
                onTouchStart={handleClick}
                onClick={() => navigate(to)}
            >
                <span className="nav-link">
                    {children}
                </span>
                {!disabled && <MDBWaves cursorPos={cursorPos} />}
            </li>:
            <li
                className={classes}
            >
                <a target={target} className="nav-link" aria-label={props.ariaLabel} href={to}>
                    {children}
                </a>
                {!disabled && <MDBWaves cursorPos={cursorPos} />}
            </li>}
        </>
    );
}

export default customLink;