import './style/layout.scss'

import Footer from './footer'
import Navbar from './navbar'
import PropTypes from 'prop-types'
import React from 'react'

const Layout = ({ children, loc, fb_id }) => (
  <>
    <Navbar />
    {children}
    <Footer loc={loc} fb_id={fb_id} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loc: PropTypes.string,
}

export default Layout
