import { Link, graphql, useStaticQuery } from 'gatsby'
import {
  MDBCollapse,
  MDBContainer,
  MDBHamburgerToggler,
  MDBIcon,
  MDBNavbar,
  MDBNavbarNav,
} from 'mdbreact'
import React, { useState } from 'react'

import CustomNavLink from './customlink'
import Logo from '../pages/images/genesislogo.png'
import LogoMobile from '../pages/images/genesislogomobile.png'
import styled from 'styled-components'

const MDBNavbarNavComp = styled(MDBNavbarNav)`
  font-size: 0.95rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 0.9rem !important;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 0.75rem;
    white-space: nowrap;
    span {
      padding-right: 2px !important;
      padding-left: 2px !important;
    }
  }
`

const CustomNavLinkComp = styled(CustomNavLink)`
  cursor: pointer;
`

const NavbarPage = () => {
  const data = useStaticQuery(graphql`
    {
      allMenuLinkContentMenuLinkContent(
        sort: { fields: [weight], order: ASC }
        filter: { menu_name: { eq: "main" } }
      ) {
        edges {
          node {
            drupal_id
            title
            link {
              uri
              title
            }
            drupal_parent_menu_item
            menu_name
          }
        }
        totalCount
      }
    }
  `)

  let menuData = data.allMenuLinkContentMenuLinkContent.edges

  const [collapseID, setCollapseID] = useState(false)

  const toggleSingleCollapse = (collapseId) => {
    setCollapseID(!collapseId)
  }

  return (
    <MDBNavbar
      color="red-bk-Genesis"
      dark
      expand="md"
      className="red-bk-Genesis"
      id="navbarid"
    >
      <MDBContainer>
        <Link to="/">
          <img src={Logo} alt="Genesis Logo" className={`d-none d-md-block`} />
          <img
            src={LogoMobile}
            alt="Genesis Logo Mobile"
            className={`d-md-none`}
          />
        </Link>
        <Link aria-label="findlocations" to="/findlocations">
          <span className="d-none">findlocations</span>
          <MDBIcon
            icon="fas fa-map-marked-alt"
            size="1x"
            className="white-link-Genesis navicons"
          />
        </Link>
        <Link aria-label="search" to="/search">
          <span className="d-none">search</span>
          <MDBIcon
            icon="fas fa-search"
            size="1x"
            className="white-link-Genesis navicons"
          />
        </Link>
        <MDBHamburgerToggler
          id="hamburger1"
          title="hamburger"
          className="d-inline d-md-none"
          onClick={() => toggleSingleCollapse(collapseID)}
        />
        {/*<input type="checkbox" data-test="hamburger-toggler" className="hamburger-button__checkbox" title="hamburger" id="hamburger1" onClick={()=> toggleSingleCollapse(collapseID)}/>
          <label id="nav-icon1" className="hamburger-button__button d-inline d-md-none" htmlFor="hamburger1">
            <span><span className="d-none">hamburger click</span></span>
            <span></span>
            <span></span>
          </label>*/}
        <MDBCollapse isOpen={collapseID} navbar>
          <MDBNavbarNavComp right className="white-link-Genesis">
            {menuData.map((item, key) => (
              <CustomNavLinkComp
                key={key}
                to={item.node.link.uri.toLowerCase().replace('internal:', '')}
                className="white-link-Genesis"
                nowrap
              >
                {item.node.title}
              </CustomNavLinkComp>
            ))}
          </MDBNavbarNavComp>
          <MDBNavbarNav left className="d-flex align-items-left">
            <CustomNavLinkComp
              ariaLabel="linkedin - opens in a new window top"
              target="_blank"
              to="https://www.linkedin.com/company/genesis-healthcare"
            >
              <span className="d-none">linkedin - opens in a new window</span>
              <MDBIcon fab icon="linkedin" className="ml-1" />
            </CustomNavLinkComp>
            <CustomNavLinkComp
              ariaLabel="facebook - opens in a new window top"
              target="_blank"
              to="https://www.facebook.com/Myskillednursingcare/"
            >
              <span className="d-none">facebook - opens in a new window</span>
              <MDBIcon fab icon="facebook" className="ml-1" />
            </CustomNavLinkComp>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default NavbarPage
